/* eslint-disable react/prop-types */
import React, { useEffect, useState } from "react";
import "./DocumentsTable.scss";
import DocumentService from "../../../services/DocumentService";
import dateFormat from "dateformat";
import FileViewerOverlay from "../../../reusable/file_viewer/FileViewerOverlay";
import useMobileView from "../../../hooks/UseMobileView";
import { FILE_CONTSTANTS } from "../../../constants/FileConstants";
import toast from "react-hot-toast";
import TextField from "../../../reusable/TextField";

let debounceTimeoutId;

// eslint-disable-next-line react/prop-types
const DocumentsTable = ({ setActiveFile, activeIndex, setActiveIndex, fileAction, setFileAction, onClose, onCloseDropzone, currentClient }) => {
	const [files, setFiles] = useState([]);
	const [isViewerOpen, setViewerOpen] = useState(false);
	const [currentFile, setCurrentFile] = useState("");
	const isMobileView = useMobileView();
	const [searchInput, setSearchInput] = useState("");

	// Function to open the file viewer with a specific URL
	const openFileViewer = ({ previewLink }) => {
		setViewerOpen(true);
		setCurrentFile(previewLink);
	};

	// Function to close the File viewer
	const closeFileViewer = () => {
		setViewerOpen(false);
		setFileAction(null);
	};

	const handleToggle = (index) => {
		setActiveIndex(index !== activeIndex ? index : null);
	};

	const triggerAction = async () => {
		if (fileAction === FILE_CONTSTANTS.download) {
			await onDownloadFile({ files, activeIndex });
		}

		if (fileAction === FILE_CONTSTANTS.view) {
			let previewLink = await getPreviewLink({ files, activeIndex });
			openFileViewer({ previewLink });
		}

		if (fileAction === FILE_CONTSTANTS.delete) {
			await onDeleteFile({ activeIndex, files, onClose, getList });
		}
	};

	const getList = async () => {
		try {
			let result = [];

			if (currentClient) {
				result = await DocumentService.getList({
					clientId: currentClient.id,
				});
			}

			// sort by year end (category field) from least to greatest
			result.sort((a, b) => b.category - a.category);

			setFiles(result);
		} catch (error) {
			console.log(error);
		}

		let searchPayload = {
			clientId: currentClient.id,
		};

		if (searchInput && searchInput.length > 0) {
			searchPayload.search = searchInput;
		}

		let result = await DocumentService.getList(searchPayload);

		setFiles(result);
	};

	const onSearchInputChange = (e) => {
		let value = e.target.value;
		setSearchInput(value);

		clearTimeout(debounceTimeoutId);

		debounceTimeoutId = setTimeout(() => {
			getList({ search: value });
		}, 500);
	};

	const onReadableFileSize = (attachmentSize) => {
		const DEFAULT_SIZE = 0;
		const fileSize = attachmentSize ?? DEFAULT_SIZE;

		if (!fileSize) {
			return `${DEFAULT_SIZE} KB`;
		}

		const sizeInKb = fileSize / 1024;

		if (sizeInKb > 1024) {
			return `${(sizeInKb / 1024).toFixed(2)} MB`;
		} else {
			return `${sizeInKb.toFixed(2)} KB`;
		}
	};

	const onDeleteFile = async ({ activeIndex, files, onClose, getList }) => {
		try {
			const file = files[activeIndex];

			let response = await DocumentService.delete({
				documentId: file.id,
			});

			if (!response) {
				throw new Error("Failed to download document preview");
			}

			toast.dismiss();
			onClose();
			toast.success("Successfully deleted!");
			getList();
		} catch (error) {
			console.error(error);
			toast.dismiss();
			toast.error("Sorry, something went wrong.");
		}
	};

	const onDownloadFile = async ({ files, activeIndex }) => {
		try {
			const file = files[activeIndex];

			let response = await DocumentService.previewStreamLink({
				documentId: file.id,
			});

			if (!response) {
				throw new Error("Failed to download document preview");
			}

			const link = document.createElement("a");
			link.href = response;
			link.download = file.name + "." + file.extension;
			document.body.appendChild(link);
			link.click();
			document.body.removeChild(link);

			toast.dismiss();
			toast.success("Successfully downloaded!");
		} catch (error) {
			console.error(error);
			toast.dismiss();
			toast.error("Sorry, something went wrong.");
		}
	};

	const getPreviewLink = async ({ files, activeIndex }) => {
		try {
			if (files.length === 0 || activeIndex < 0 || activeIndex >= files.length) {
				throw new Error("Invalid file selection");
			}

			const file = files[activeIndex];

			if (!file || !file.id) {
				throw new Error("File data is missing or incomplete");
			}

			if (file.shouldThrowError) {
				throw new Error("Simulated error");
			}

			const previewLink = await DocumentService.previewStreamLink({ documentId: file.id });

			if (!previewLink) {
				throw new Error("Failed to load document preview");
			}

			toast.dismiss();

			return previewLink;
		} catch (error) {
			console.error(error);
			toast.dismiss();
			toast.error("Sorry, something went wrong.");
		}
	};

	useEffect(() => {
		triggerAction();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [fileAction]);

	useEffect(() => {
		getList();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [onCloseDropzone, currentClient]);

	let prevYear = null; // Track previous year

	return (
		<>
			<FileViewerOverlay
				isOpen={isViewerOpen}
				fileViewURL={currentFile}
				fileName={files ? files[activeIndex]?.name + files[activeIndex]?.extension : ""}
				onClose={closeFileViewer}
				onDownload={() => onDownloadFile({ files, activeIndex })}
			/>

			<table className="file-table documents-table">
				<thead className="documents-table-head">
					<tr className="documents-table-head-tr">
						<th className="documents-table-head-th documents-table-head-th--name">Name</th>
						<th className="documents-table-head-th documents-table-head-th--date">Date</th>
						<th className="documents-table-head-th documents-table-head-th--time">Time</th>
						<th className="documents-table-head-th documents-table-head-th--file-size">File Size</th>
						<th className="documents-table-head-th documents-table-head-th--action">{/* Action */}</th>
					</tr>
				</thead>
				<tbody>
					{files &&
						files.map((file, index) => {
							const currentYear = file.category ? file.category : "Not Specified";
							const isFirstFile = index === 0;
							const isYearChanged = prevYear !== currentYear;

							// Update prevYear for next iteration
							prevYear = currentYear;

							return (
								<React.Fragment key={index}>
									{isFirstFile && (
										<tr className="documents-table-row--category-divider">
											<td className="documents-table-row--category-divider__label" colSpan={5}>
												Year {currentYear}
											</td>
										</tr>
									)}
									{isYearChanged && !isFirstFile && (
										<tr className="documents-table-row--category-divider">
											<td className="documents-table-row--category-divider__label" colSpan={5}>
												Year {currentYear}
											</td>
										</tr>
									)}
									<tr
										className={`documents-table-row ${index === activeIndex ? "active-row" : ""}`}
										onClick={() => {
											handleToggle(index);
											if (activeIndex !== index) {
												setActiveFile(files[index]);
											}
										}}
									>
										<td className="documents-table-row-td documents-table-row-td--name">
											<img
												className="documents-table-row-td--name__file-icon"
												alt="file filled icon"
												src={"./assets/file-icon-filled.svg"}
											/>
											<span className="documents-table-row-td--name__txt">{file.name}</span>
										</td>
										{file.created_at && (
											<td className="documents-table-row-td documents-table-row-td--date">
												{dateFormat(file.created_at, isMobileView ? "mm/dd/yy" : "mmm dS, yyyy")}
											</td>
										)}
										<td className="documents-table-row-td documents-table-row-td--time">
											{file.created_at ? dateFormat(file.created_at, "h:MM TT") : ""}
										</td>
										{!isMobileView && (
											<>
												<td className="documents-table-row-td documents-table-row-td--file-size">
													{file.size ? onReadableFileSize(file.size) : ""}
												</td>
												<td className="documents-table-row-td documents-table-row-td--action"></td>
											</>
										)}
									</tr>
								</React.Fragment>
							);
						})}
				</tbody>
			</table>
		</>
	);
};

export default DocumentsTable;
