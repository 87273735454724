import React, { useState, useEffect } from "react";
import "./NewAccTransitionView.scss";

const NewAccTransitionView = () => {
	const [stage, setStage] = useState(1);

	useEffect(() => {
		const timer = setTimeout(() => {
			// Transition to the next stage after a delay
			setStage((prevStage) => prevStage + 1);
		}, 2000); // Adjust the delay as needed

		// Clear the timer when the component unmounts
		return () => clearTimeout(timer);
	}, [stage]);

	const TRANSITION_STAGE = {
		CREATING_ACCOUNT: 1,
		ACCOUNT_CREATED: 2,
	};

	const renderStage = () => {
		switch (stage) {
			case TRANSITION_STAGE.CREATING_ACCOUNT:
				return (
					<div className="new-acc-transition-view-1">
						<span className="new-acc-transition-view-1__title">Creating Account...</span>
						<img className="new-acc-transition-view-1__icon" alt="spinner icon" src={"./assets/spinner.svg"} />
					</div>
				);
			case TRANSITION_STAGE.ACCOUNT_CREATED:
				return (
					<div className="new-acc-transition-view-2">
						<span className="new-acc-transition-view-2__title">Account Created!</span>
						<p className="new-acc-transition-view-2__subtitle">Logging you in now...</p>
					</div>
				);
			default:
				return null;
		}
	};

	return (
		<div className="container">
			<div className={stage === 2 ? "hidden" : ""}>{renderStage()}</div>
		</div>
	);
};

export default NewAccTransitionView;
