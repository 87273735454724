import React, { useState } from "react";
import "./Documents.scss";
import DocumentsHeader from "./components/DocumentsHeader";
import DocumentsTable from "./components/DocumentsTable";
import Dropzone from "./components/Dropzone";
import useMobileView from "../../hooks/UseMobileView";

// eslint-disable-next-line react/prop-types
const Documents = ({ currentClient }) => {
	const [activeIndex, setActiveIndex] = useState(null);
	const [fileAction, setFileAction] = useState(null);
	const [loading, setLoading] = useState(false);
	const [fileURL, setFileURL] = useState(null);
	const [showDropzone, setShowDropzone] = useState(false); // New state to control Dropzone visibility
	const [files, setFiles] = useState([]); // New state for tracking uploaded files
	const isMobileView = useMobileView();
	const [activeFile, setActiveFile] = useState(null);
	const onFilesAdded = (newFiles) => {
		setFiles((prevFiles) => [...prevFiles, ...newFiles]);
		// Implement file upload logic or further processing here
	};

	const onClose = () => {
		setActiveIndex(null);
		setFileAction(null);
	};
	const onCloseDropzone = () => {
		setShowDropzone(false);
		setFiles([]);
	};

	return (
		<div
			className="documents"
			onDragOver={(e) => {
				e.preventDefault();
				setShowDropzone(true); // Show Dropzone when item is dragged over
			}}
			onDragLeave={(e) => {
				e.preventDefault();
			}}
			// Optionally, you might want to handle onDrop to hide the dropzone as well
			onDrop={(e) => {
				e.preventDefault();
				setShowDropzone(true); // Hide Dropzone after dropping the item
			}}
		>
			{showDropzone && <Dropzone onFilesAdded={onFilesAdded} files={files} onClose={onCloseDropzone} />}

			<DocumentsHeader
				activeFile={activeFile}
				hideTitle={activeIndex !== null && isMobileView}
				activeIndex={activeIndex}
				onClose={onClose}
				setFileAction={setFileAction}
				loading={loading}
				setShowDropzone={setShowDropzone}
			/>

			<DocumentsTable
				setActiveFile={setActiveFile}
				currentClient={currentClient}
				setLoading={setLoading}
				activeIndex={activeIndex}
				setActiveIndex={setActiveIndex}
				fileAction={fileAction}
				setFileAction={setFileAction}
				onClose={onClose}
				setFileURL={setFileURL}
				fileURL={fileURL}
				onCloseDropzone={onCloseDropzone}
			/>
		</div>
	);
};

export default Documents;
