import React from "react";
import "./MainLayoutNavbar.scss";
import { useNavigate } from "react-router-dom";

const MainLayoutNavbar = () => {
	const navigate = useNavigate();

	return (
		<div className="main-layout-navbar">
			<img
				className="main-layout-navbar__logo"
				alt="srj logo"
				src={"./assets/srj_main_logo.svg"}
				onClick={() => {
					navigate("/");
				}}
			/>
		</div>
	);
};

export default MainLayoutNavbar;
