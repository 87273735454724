import React from "react";
import ReactDOM from "react-dom";
import "./FileViewerOverlay.scss"; // Make sure to create and import the corresponding SCSS file
import DocViewer, { CSVRenderer, JPGRenderer, MSDocRenderer, PDFRenderer, PNGRenderer, TXTRenderer } from "@cyntler/react-doc-viewer";
import { Download as DownloadIcon, X as CloseIcon } from "react-feather";
// Import the necessary styles for the document viewer
import "react-pdf/dist/Page/AnnotationLayer.css";
import "react-pdf/dist/Page/TextLayer.css";

import CustomBtn from "../custom_btn/CustomBtn";

const docViewerThemeConfig = {
	primary: "#000000",
	textPrimary: "#ffffff",
	textSecondary: "#5296d8",
	textTertiary: "#00000099",
	disableThemeScrollbar: true,
};

// eslint-disable-next-line react/prop-types
const FileViewerOverlay = ({ isOpen, fileViewURL, onClose, onDownload, fileName }) => {
	if (!isOpen) return null;

	const docs = [
		{
			uri: fileViewURL,
			key: fileViewURL,
			fileName: fileName,
		},
	];

	const headerRender = (data) => {
		const fileName = data.currentDocument.fileName;
		return (
			<div className="file-viewer-header">
				<p className="file-viewer-header__file-name">{fileName}</p>
				<div className="file-viewer-header__actions">
					<CustomBtn view="primary" className="file-viewer-header__actions--btn" onClick={onDownload} label={<DownloadIcon />} />
					<CustomBtn view="primary" className="file-viewer-header__actions--btn" onClick={onClose} label={<CloseIcon />} />
				</div>
			</div>
		);
	};

	const spinnerRender = () => {
		return (
			<div className="file-viewer-spinner">
				<img alt="spinner icon" src={"./assets/spinner.svg"} width={100} />
			</div>
		);
	};

	const errorRender = () => {
		return (
			<div className="file-viewer-error-render">
				<p>Error</p>
			</div>
		);
	};

	const unPreviewableRender = () => {
		return (
			<div className="file-viewer-unpreviewable-render">
				<img alt="file filled icon" src={"./assets/file-icon-filled.svg"} className="file-viewer-unpreviewable-render__icon" />
				<p>Document not previewable</p>
			</div>
		);
	};

	return ReactDOM.createPortal(
		<div className="file-viewer-overlay" onClick={onClose}>
			<div className="file-viewer-overlay__viewer-container" onClick={(e) => e.stopPropagation()}>
				<DocViewer
					documents={docs}
					initialActiveDocument={docs[1]}
					pluginRenderers={[PDFRenderer, PNGRenderer, CSVRenderer, TXTRenderer, JPGRenderer, MSDocRenderer]}
					theme={docViewerThemeConfig}
					config={{
						header: {
							overrideComponent: headerRender,
						},
						loadingRenderer: {
							overrideComponent: spinnerRender,
						},
						errorRenderer: {
							overrideComponent: errorRender,
						},
						noRenderer: {
							overrideComponent: unPreviewableRender,
						},
					}}
				/>
			</div>
		</div>,
		document.body,
	);
};

export default FileViewerOverlay;
