import React, { useState } from "react";
import TabComponent from "./tab_component/TabComponent";

const Sidebar = () => {
	const [activeTab, setActiveTab] = useState("Documents");

	return (
		<div className="sidebar">
			<div className="sidebar__header">
				<img className="sidebar__header__logo" alt="srj logo" src={"./assets/srj-client-portal-logo.svg"} />
			</div>

			<div className="sidebar__body">
				<TabComponent
					activeTab={activeTab}
					setActiveTab={setActiveTab}
					tabName="Documents"
					activeIcon="./assets/documents--active.svg"
					defaultIcon="./assets/documents--default.svg"
				/>
			</div>
		</div>
	);
};

export default Sidebar;
