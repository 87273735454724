import React from "react";
import useForm from "../../hooks/useForm";
import AuthLayout from "../../layout/AuthLayout";
import MainLayoutNavbar from "../../layout/MainLayoutNavbar";
import TextField from "../../reusable/TextField";
import "../create_password/CreatePassword.scss";
import CustomBtn from "../../reusable/custom_btn/CustomBtn";
import ErrorMsg from "../../reusable/error_msg/ErrorMsg";
import "./Login.scss";
import AuthService from "../../services/AuthService";
import { useAuth } from "../../contexts/AuthContext";
import { useNavigate } from "react-router-dom";

// eslint-disable-next-line react/prop-types
const Login = () => {
	const { login } = useAuth();
	const navigate = useNavigate();

	const onSubmit = async () => {
		const newErrors = validateInputs(inputs);
		if (Object.keys(newErrors).length > 0) {
			setErrors(newErrors);
			return;
		}

		try {
			const response = await AuthService.login({
				email: inputs.email,
				password: inputs.password,
			});

			if (!response) {
				setErrors({ _general: "Invalid email or password. Please try again." });
				return;
			}

			const userData = response.data.userData;
			const updatedAtString = userData.updated_at;
			const updatedAtDate = new Date(updatedAtString);

			// Now you can use getDate on updatedAtDate
			const dayOfMonth = updatedAtDate.getDate();

			login({
				authData: response.data.authData,
				dayOfMonth,
				updatedAtDate,
				userData: userData,
			});
		} catch (error) {
			console.error(error);
			// Handle errors
		}
	};

	const validateInputs = (inputs) => {
		const newErrors = {};

		// Validate email format
		if (!inputs.email) {
			newErrors.email = "Email is required";
		}

		// Validate password
		if (!inputs.password) {
			newErrors.password = "Password is required";
		}

		return newErrors;
	};

	// Call the useForm hook
	const { handleInputChange, handleSubmit, inputs, setErrors, errors } = useForm(
		onSubmit,
		{
			email: "",
			password: "",
		},
		validateInputs,
	);

	return (
		<div className="create-password">
			<MainLayoutNavbar />

			<AuthLayout>
				<form className="create-password__form" onSubmit={handleSubmit}>
					<span className="create-password__form__title">Login</span>

					<div className="create-password__form__inputs">
						<TextField view="primary" title="" name="email" placeholder="Email" value={inputs.email} type="email" onChange={handleInputChange} />

						{errors.email && <ErrorMsg txt={errors.email} />}

						<TextField
							customStyling={{ marginTop: 24 }}
							view="primary"
							title=""
							name="password"
							placeholder="Password"
							value={inputs.password}
							type="password"
							onChange={handleInputChange}
						/>

						<div className="login__form__inputs__reset-pass">
							<span
								className="login__form__inputs__reset-pass-link"
								onClick={() => {
									navigate("/reset-password");
								}}
							>
								Reset Password
							</span>{" "}
						</div>

						{errors.password && <ErrorMsg txt={errors.password} />}
					</div>

					{/* Display general form error */}
					{errors._general && <ErrorMsg txt={errors._general} />}

					<CustomBtn view="primary" label="Continue" onClick={onSubmit} />
				</form>
			</AuthLayout>
		</div>
	);
};

export default Login;
