import React from "react";
import PropTypes from "prop-types";

import "./PlaidLandingPage.scss";
import LandingPageIllustration from "./LandingPageIllustration";

const PlaidLandingPage = ({ open, ready }) => {
	const handleConnect = () => {
		if (ready) {
			open();
		}
	};

	return (
		<div className="plaid-landing-page">
			<div className="logo">
				<img src="https://www.srjca.com/wp-content/uploads/2020/10/SRJ-CPA-Logo-v2-1.png" alt="SRJ logo" />
			</div>
			<div className="main-card">
				<div className="main-card-heading">Connect your bank accounts</div>
				<div className="divider"></div>
				<LandingPageIllustration />
				<button className="btn--primary" type="button" onClick={handleConnect} disabled={!ready}>
					Link my accounts
				</button>
				<div className="legal-agreements">
					By continuing, I agree to SRJCA&apos;s <br />
					<a href="/terms-of-service">Terms of Service</a> and <a href="/privacy-policy.pdf">Privacy Policy</a>
				</div>
			</div>
		</div>
	);
};

PlaidLandingPage.propTypes = {
	open: PropTypes.func.isRequired,
	ready: PropTypes.bool.isRequired,
};

export default PlaidLandingPage;
