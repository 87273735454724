import React from "react";
import PropTypes from "prop-types";
import "./AuthLayout.scss";

const AuthLayout = ({ children }) => {
	return <div className="auth-layout">{children}</div>;
};

AuthLayout.propTypes = {
	children: PropTypes.node.isRequired,
};

export default AuthLayout;
