import React from "react";

import "./CustomBtn.scss";

const CustomBtn = ({ view, label, className, customStyling = {}, onClick, disabled }) => {
	const PrimaryBtn = (
		<button className={`custom-btn--primary ${className}`} type="button" style={customStyling} onClick={onClick} disabled={disabled}>
			{label}
		</button>
	);

	if (!disabled) {
		disabled = false;
	}

	const SecondaryBtn = (
		<button className={`custom-btn--secondary ${className}`} type="button" style={customStyling} onClick={onClick} disabled={disabled}>
			{label}
		</button>
	);

	const renderView = () => {
		if (view == "primary") {
			return PrimaryBtn;
		}

		if (view == "secondary") {
			return SecondaryBtn;
		}
	};

	return renderView();
};

export default CustomBtn;
