import React from "react";
import useForm from "../../hooks/useForm";
import AuthLayout from "../../layout/AuthLayout";
import MainLayoutNavbar from "../../layout/MainLayoutNavbar";
import TextField from "../../reusable/TextField";
import "../create_password/CreatePassword.scss";
import CustomBtn from "../../reusable/custom_btn/CustomBtn";
import ErrorMsg from "../../reusable/error_msg/ErrorMsg";
import "./ResetPassword.scss";
import toast from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import AuthService from "../../services/AuthService";

const ResetPassword = () => {
	const navigate = useNavigate();

	const validateInputs = (inputs) => {
		const newErrors = {};

		// Validate email format
		if (!inputs.email) {
			newErrors.email = "Email is required";
		}

		return newErrors;
	};

	const onSubmit = async () => {
		const newErrors = validateInputs(inputs);
		if (Object.keys(newErrors).length > 0) {
			setErrors(newErrors);
			return;
		}

		try {
			const userInput = {
				contactEmail: inputs.email,
			};

			await AuthService.emailResetPassword(userInput);

			toast("Check your email for resetting password", {
				duration: 5000,
				icon: "✅",
			});

			setTimeout(() => {
				navigate("/");
			}, 5000);
		} catch (error) {
			console.error(error);
			// Handle errors
			toast(error.response.data.message, {
				duration: 5000,
				icon: "⚠️",
			});
		}
	};
	// Call the useForm hook
	const { handleInputChange, handleSubmit, inputs, setErrors, errors } = useForm(
		onSubmit,
		{
			email: "",
		},
		validateInputs,
	);

	return (
		<div className="create-password">
			<MainLayoutNavbar />

			<AuthLayout>
				<form className="create-password__form" onSubmit={handleSubmit}>
					<span className="create-password__form__title">Reset Password</span>
					<span className="create-password__form__subtitle">
						Enter your email address in the form below and we will send you further instructions on how to reset your password
					</span>

					<div className="create-password__form__inputs">
						<TextField view="primary" title="" name="email" placeholder="Email" value={inputs.email} type="email" onChange={handleInputChange} />

						{errors.email && <ErrorMsg txt={errors.email} />}
					</div>

					<CustomBtn view="primary" label="Continue" onClick={onSubmit} />
				</form>
			</AuthLayout>
		</div>
	);
};

export default ResetPassword;
