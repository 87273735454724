import React, { useEffect, useState } from "react";
import useForm from "../../hooks/useForm";
import AuthLayout from "../../layout/AuthLayout";
import MainLayoutNavbar from "../../layout/MainLayoutNavbar";
import TextField from "../../reusable/TextField";
import "./CreatePassword.scss";
import CustomBtn from "../../reusable/custom_btn/CustomBtn";
import ErrorMsg from "../../reusable/error_msg/ErrorMsg";
import { useLocation } from "react-router-dom";
import AuthService from "../../services/AuthService";
import { useNavigate } from "react-router-dom";

import NewAccTransitionView from "./NewAccTransitionView";
import { useAuth } from "../../contexts/AuthContext";

const CreatePassword = () => {
	const navigate = useNavigate();

	const navigateToRoot = () => {
		navigate("/", { replace: true });
	};

	const { login } = useAuth();

	const [loginRedirect, setLoginRedirect] = useState(false);

	// Use the useLocation hook to get the location object
	const location = useLocation();

	// Use URLSearchParams to parse the query parameters
	const queryParams = new URLSearchParams(location.search);
	// Extract the 'token' parameter
	const token = queryParams.get("token");
	const email = queryParams.get("email");

	const validateInputs = (inputs) => {
		const newErrors = {};

		// Validate password
		if (!inputs.password) {
			newErrors.password = "Password is required";
		} else if (inputs.password.length < 6) {
			newErrors.password = "Password must be at least 6 characters";
		}

		// Validate confirmPassword
		if (!inputs.confirmPassword) {
			newErrors.confirmPassword = "Confirm Password is required";
		} else if (inputs.confirmPassword !== inputs.password) {
			newErrors.confirmPassword = "Passwords do not match";
		}

		return newErrors;
	};

	const onSubmit = async () => {
		const newErrors = validateInputs(inputs);
		if (Object.keys(newErrors).length > 0) {
			setErrors(newErrors);
			return;
		}

		try {
			const response = await AuthService.createAccount({
				email,
				setupToken: token,
				password: inputs.password,
				confirmedPassword: inputs.confirmPassword,
			});

			if (response) {
				setLoginRedirect(true);
				onLoginSubmit();
				navigateToRoot();
			}

			// Rest of your code...
		} catch (error) {
			console.error(error);
			// Handle errors
		}
	};

	const onLoginSubmit = async () => {
		try {
			const response = await AuthService.login({
				email,
				password: inputs.password,
			});

			const userData = response.data.userData;
			const updatedAtString = userData.updated_at;
			const updatedAtDate = new Date(updatedAtString);

			// Now you can use getDate on updatedAtDate
			const dayOfMonth = updatedAtDate.getDate();

			login({
				authData: response.data.authData,
				dayOfMonth,
				updatedAtDate,
				userData: response.data.userData,
			});
		} catch (error) {
			console.error(error);
			// Handle errors
		}
	};

	// Call the useForm hook
	const { handleInputChange, handleSubmit, inputs, setErrors, errors } = useForm(
		onSubmit,
		{
			password: "",
			confirmPassword: "",
		},
		validateInputs,
	);

	// check if the token is a valid token in the db
	const validToken = async () => {
		try {
			let result = await AuthService.verifyToken({ token });

			return result.data;
		} catch (error) {
			console.log(error);
		}
	};
	useEffect(() => {
		const checkTokenValidity = async () => {
			try {
				let tokenFound = await validToken(token);

				if (!tokenFound) {
					navigate("/");
				}
			} catch (error) {
				console.error(error);
				// Handle errors
			}
		};

		checkTokenValidity();
	}, [token]);

	if (loginRedirect) {
		return (
			<div className="create-password--login-redirect">
				<MainLayoutNavbar />

				<AuthLayout>
					<NewAccTransitionView />
				</AuthLayout>
			</div>
		);
	}

	return (
		<div className="create-password">
			<MainLayoutNavbar />

			<AuthLayout>
				<form className="create-password__form" onSubmit={handleSubmit}>
					<span className="create-password__form__title">Create Password</span>

					<div className="create-password__form__inputs">
						<TextField
							customClass="create-password__form__inputs__input"
							view="primary"
							title=""
							name="password"
							placeholder="Password"
							value={inputs.password}
							type="password"
							onChange={handleInputChange}
						/>

						{errors.password && <ErrorMsg txt={errors.password} />}

						<TextField
							customClass="create-password__form__inputs__input"
							view="primary"
							title=""
							name="confirmPassword"
							placeholder="Confirm Password"
							value={inputs.confirmPassword}
							type="password"
							onChange={handleInputChange}
						/>

						{errors.confirmPassword && <ErrorMsg txt={errors.confirmPassword} />}
					</div>

					<CustomBtn view="primary" label="Continue" onClick={onSubmit} />
				</form>
			</AuthLayout>
		</div>
	);
};

export default CreatePassword;
