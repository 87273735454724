/* eslint-disable react/prop-types */
// TabComponent.js

import React from "react";

const TabComponent = ({ activeTab, setActiveTab, tabName, activeIcon, defaultIcon }) => {
	let isTabSelected = activeTab === tabName;
	return (
		<div
			onClick={() => {
				setActiveTab(tabName);
			}}
			className={`sidebar__body__tab sidebar__body__tab--${tabName.toLowerCase()} ${activeTab === tabName && "sidebar__body__tab--active"}`}
		>
			{isTabSelected && <img className="sidebar__body__tab__icon" alt={`${tabName.toLowerCase()} icon`} src={activeIcon} />}
			{!isTabSelected && <img className="sidebar__body__tab__icon" alt={`${tabName.toLowerCase()} icon`} src={defaultIcon} />}

			<span className="sidebar__body__tab__txt">{tabName}</span>
		</div>
	);
};

export default TabComponent;
