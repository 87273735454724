import UserService from "./UserService";
import { ConfigurationService } from "./KichiriService";
import Kichiri from "./KichiriService";

/**
 * Provides services for managing documents, including listing, downloading, viewing, deleting, uploading, and previewing document stream links.
 */
const DocumentService = {
	/**
	 * Retrieves a list of documents for a given client ID.
	 *
	 * @param {number} clientId - The ID of the client whose documents are to be retrieved.
	 * @param {string} search - The search string to filter the documents.
	 * @returns {Promise<Object>} The API response containing the list of documents.
	 */
	getList: async ({ clientId, search }) => {
		try {
			let storedData = await UserService.getFullUserData();

			if (clientId) {
				const response = await Kichiri.client_portal.listFiles(
					{},
					{
						clientId,
						search,
					},
					storedData.auth_token,
				);

				return response.data;
			}
		} catch (error) {
			console.log(error);
		}
	},

	/**
	 * Deletes a document specified by its document ID.
	 *
	 * @param {number} documentId - The ID of the document to delete.
	 * @returns {Promise<Object>} The API response for the delete request.
	 */
	delete: async ({ documentId }) => {
		try {
			let storedData = await UserService.getFullUserData();

			const response = await Kichiri.client_portal.deleteFile(
				{
					documentId,
					clientId: storedData.client.id,
					companyId: 1,
				},
				{},
				storedData.auth_token,
			);

			return response.data;
		} catch (error) {
			console.log(error);
		}
	},

	/**
	 * Uploads documents for a user.
	 *
	 * @param {File[]} files - An array of files to be uploaded.
	 * @returns {Promise<Object>} The API response for the upload request.
	 */
	upload: async ({ files }) => {
		try {
			let storedData = await UserService.getFullUserData();
			let clientId = storedData.client.id;

			const formData = new FormData();
			formData.append("companyId", 1);
			formData.append("clientId", clientId);
			files.forEach((file) => {
				formData.append("files", file, file.name);
			});

			const response = await Kichiri.client_portal.uploadFile(formData, {}, storedData.auth_token);
			return response.data;
		} catch (error) {
			console.error(error);
		}
	},

	/**
	 * Generates a streaming link for a document specified by its document ID.
	 *
	 * @param {number} documentId - The ID of the document for which the stream link is generated.
	 * @returns {string} The constructed URL for the document stream.
	 */
	previewStreamLink: async ({ documentId }) => {
		try {
			let storedData = await UserService.getFullUserData();
			let clientId = storedData.client.id;

			let link = `${ConfigurationService.resolveApi().rest}/client_portal/file/download?authToken=${storedData.auth_token}&companyId=1&documentId=${documentId}&clientId=${clientId}`;
			return link;
		} catch (error) {
			console.log(error);
		}
	},
};

export default DocumentService;
