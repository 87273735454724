import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import { Toaster } from "react-hot-toast";

import CreatePassword from "./pages/create_password/CreatePassword";
import Login from "./pages/login/Login";
import ResetPassword from "./pages/reset_password/ResetPassword";
import PrivateRoutes from "./routes/PrivateRoutes";
import { useAuth } from "./contexts/AuthContext";
import PlaidConnect from "./pages/plaid/PlaidConnect";
import PlaidConnectSuccess from "./pages/plaid/PlaidConnectSuccess";

function App() {
	const { loggedIn } = useAuth();

	return (
		<div className="App">
			<Router>
				<Routes>
					{loggedIn && (
						<>
							<Route path="*" element={<PrivateRoutes />} />
						</>
					)}
					{!loggedIn && (
						<>
							<Route path="/" element={<Login />} />
							<Route path="/create-password" element={<CreatePassword />} />
							<Route path="/reset-password" element={<ResetPassword />} />
							<Route path="/connect/:publicId" element={<PlaidConnect />} />
							<Route path="/plaid-connect-success" element={<PlaidConnectSuccess />} />
						</>
					)}
				</Routes>
			</Router>
			<Toaster containerStyle={{ zIndex: 999999999999999 }} />
		</div>
	);
}

export default App;
