import { useState, useEffect } from "react";

function useMobileView() {
	const [isMobileView, setIsMobileView] = useState(window.innerWidth <= 768);

	useEffect(() => {
		function handleResize() {
			setIsMobileView(window.innerWidth <= 768);
		}

		window.addEventListener("resize", handleResize);

		return () => {
			window.removeEventListener("resize", handleResize);
		};
	}, []);

	return isMobileView;
}

export default useMobileView;
