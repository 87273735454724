import React from "react";

import PropTypes from "prop-types";
import "./TextField.scss";

const TextField = ({ view, title, name, placeholder, value, info, error, type, customClass = "", customStyling = {}, onChange }) => {
	const PrimaryInput = (
		<div className="text-field--primary">
			<span className="text-field--primary__title">{title}</span>
			<input
				type={type}
				className={"text-field--primary__input " + (customClass && customClass)}
				placeholder={placeholder}
				name={name}
				value={value}
				onChange={onChange}
				autoComplete="off"
				style={customStyling}
			/>
			{info && <small className="lw-portal-text-input-info">{info}</small>}
			{error && <div className="invalid-feedback">{error}</div>}
		</div>
	);

	const renderView = () => {
		if (view === "primary") {
			return PrimaryInput;
		}
	};

	return renderView();
};

TextField.propTypes = {
	view: PropTypes.string.isRequired,
	title: PropTypes.string,
	name: PropTypes.string,
	placeholder: PropTypes.string,
	value: PropTypes.string.isRequired,
	info: PropTypes.string,
	error: PropTypes.string,
	onChange: PropTypes.func.isRequired,
	disableInput: PropTypes.bool,
};

export default TextField;
