import React, { useEffect, useState, useCallback } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { usePlaidLink } from "react-plaid-link";

import "./PlaidConnect.scss";
import PlaidService from "../../services/PlaidService";
import PlaidLandingPage from "./PlaidLandingPage";

// eslint-disable-next-line react/prop-types
const PlaidLink = ({ payload, onConnect }) => {
	const navigate = useNavigate();
	// eslint-disable-next-line react/prop-types
	let { client_id, public_id, link_token } = payload;

	let onSuccess = useCallback(async (public_token, metadata) => {
		console.log({ public_token, metadata });

		// let plaidLink = await PlaidService.completeSetup({
		// 	clientId: client_id,
		// 	publicId: public_id,
		// 	publicToken: public_token,
		// 	payload: metadata,
		// });

		// onConnect(plaidLink);

		navigate("/plaid-connect-success");
	}, []);

	const config = {
		token: link_token,
		onSuccess,
	};

	let { open, ready } = usePlaidLink(config);

	return <PlaidLandingPage open={open} ready={ready} />;
};

const PlaidConnect = () => {
	const navigate = useNavigate();
	let { publicId } = useParams();
	let [loading, setLoading] = useState(false);
	let [plaidLink, setPlaidLink] = useState(null);

	useEffect(() => {
		let run = async () => {
			if (loading) {
				return;
			}

			setLoading(true);

			let response = await PlaidService.getPlaidLink({ plaidLinkPublicId: publicId });

			setPlaidLink(response);
			setLoading(false);
		};

		run();
	}, []);

	let onConnect = useCallback((plaidLink) => {
		console.log(plaidLink);
	});

	return (
		<>
			<div>{plaidLink && <PlaidLink payload={plaidLink} onConnect={onConnect} />}</div>
		</>
	);
};

export default PlaidConnect;
