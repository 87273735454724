import { USER_KEY, USER_TOKEN_KEY, USER_TOKEN_KEY_EXPIRY } from "../constants/AuthConstants";
import Kichiri from "./KichiriService";

const UserService = {
	/**
	 * Stores user data to the localstorage
	 *
	 * @param {UserResponse} userData
	 * @return {Promise}
	 */
	storeUserData: ({ userData, token, expirationDate }) => {
		try {
			// Store token and expiration in localStorage
			let userDataString = JSON.stringify(userData);
			localStorage.setItem(USER_KEY, JSON.stringify(userData));
			localStorage.setItem(USER_TOKEN_KEY, token);
			localStorage.setItem(USER_TOKEN_KEY_EXPIRY, expirationDate);

			return userDataString;
		} catch (error) {
			console.log(error);
		}
	},

	/**
	 * Clears the user data from localstorage
	 *
	 * @return {Promise}
	 */
	clearUserData: () => {
		try {
			localStorage.removeItem(USER_KEY);
			localStorage.removeItem(USER_TOKEN_KEY);
			localStorage.removeItem(USER_TOKEN_KEY_EXPIRY);
			localStorage.removeItem("currentClient");

			return;
		} catch (error) {
			console.log(error);
		}
	},

	/**
	 * Fetches all the user data for the current session, should be used with caution
	 *
	 * @return {Promise}
	 */
	getFullUserData: () => {
		try {
			let userData = localStorage.getItem(USER_KEY);
			userData = JSON.parse(userData);

			let clientData = localStorage.getItem("currentClient");
			clientData = JSON.parse(clientData);

			userData = {
				...userData,
				client: clientData,
			};

			return userData;
		} catch (error) {
			console.log(error);
		}
	},

	/**
	 * Check to see if the user is already logged in
	 *
	 * @return {Promise}
	 */
	getAuthToken: () => {
		let data = null;
		try {
			data = localStorage.getItem(USER_TOKEN_KEY);
			return data;
		} catch (error) {
			console.log(error);
		}

		return data;
	},

	/**
	 * Asynchronously fetches a list of clients associated with the current user.
	 *
	 * @returns {Promise<Object[]>} A promise that resolves with the list of clients on success, or an empty array on failure.
	 */
	async fetchClients() {
		try {
			let storedData = await UserService.getFullUserData();

			const response = await Kichiri.client_portal.getClients(
				{
					contactId: storedData.id,
				},
				{},
				storedData.auth_token,
			);

			return response.data;
		} catch (error) {
			console.log(error);
		}

		return [];
	},

	/**
	 * @description Fetch user name and return the initials
	 * @returns {string}
	 */
	getCurrentUserInitials() {
		const presetInitials = "JD";

		const currentUser = JSON.parse(localStorage.getItem("c_portal_user"));

		return currentUser.first_name.charAt(0) + currentUser.last_name.charAt(0) || presetInitials;
	},
};

export default UserService;
