import { React } from "react";

import "./PlaidConnectSuccess.scss";
import SuccessPageIllustration from "./SuccessPageIllustration";

const PlaidConnectSuccess = () => {
	return (
		<div className="plaid-success-page">
			<h1>Success!!</h1>
			<SuccessPageIllustration />
			<p className="plaid-success-page__message">Your bank account/(s) have been successfully connected. </p>
			<p className="plaid-success-page__close__message">You may now close this page.</p>
			<p className="plaid-success-page__contact">
				Questions? Contact us at <span style={{ textDecoration: "underline" }}>accounts@srjca.com</span>
			</p>
		</div>
	);
};

export default PlaidConnectSuccess;
