/* eslint-disable react/prop-types */
import React, { useEffect, useRef, useState } from "react";
import "./TopNavbar.scss";

import { useAuth } from "../../contexts/AuthContext";

import { LogOut } from "react-feather";

const TopNavbar = ({ userClients, currentClient, setCurrentClient, currentUserInitials }) => {
	const [openDropdown, setOpenDropdown] = useState(false);
	const [openClientDropdown, setOpenClientDropdown] = useState(false);
	const clientDropdownRef = useRef(null);
	const dropdownRef = useRef(null);
	const toggleRef = useRef(null);
	const clientToggleRef = useRef(null);
	const letterRef = useRef(null);
	const clientRef = useRef(null);

	const { logout } = useAuth();

	useEffect(() => {
		const handleClickOutsideDropdown = (event) => {
			if (dropdownRef.current && !dropdownRef.current.contains(event.target) && !toggleRef.current.contains(event.target)) {
				setOpenDropdown(false);
			}
		};

		document.addEventListener("mousedown", handleClickOutsideDropdown);

		return () => {
			document.removeEventListener("mousedown", handleClickOutsideDropdown);
		};
	}, []);

	useEffect(() => {
		const handleClickOutsideClientDropdown = (event) => {
			if (clientDropdownRef.current && !clientDropdownRef.current.contains(event.target) && !clientToggleRef.current.contains(event.target)) {
				setOpenClientDropdown(false);
			}
		};

		document.addEventListener("mousedown", handleClickOutsideClientDropdown);

		return () => {
			document.removeEventListener("mousedown", handleClickOutsideClientDropdown);
		};
	}, []);

	const toggleDropdown = () => {
		setOpenDropdown((prevOpenDropdown) => !prevOpenDropdown);
	};

	const toggleClientDropdown = () => {
		setOpenClientDropdown((prevOpenClientDropdown) => !prevOpenClientDropdown);
	};

	return (
		<div className="top-navbar">
			<div className="top-navbar__inner">
				{userClients.length > 0 && (
					<>
						<div className="top-navbar__inner__toggle--client" onClick={toggleClientDropdown} ref={clientToggleRef}>
							<span className="top-navbar__inner__toggle--client__txt" ref={clientRef}>
								{currentClient.name}
							</span>
						</div>

						{openClientDropdown && (
							<div className="top-navbar__inner__dropdown--client " ref={clientDropdownRef}>
								{userClients.map((client, index) => {
									return (
										<span
											className="top-navbar__inner__dropdown__tab__txt"
											key={index}
											onClick={async () => {
												setCurrentClient(client);
												setOpenClientDropdown(false);
											}}
										>
											<span className="top-navbar__inner__dropdown__tab__txt__name">{client.name}</span>
											{/* <span>{client.phone_number}</span> */}
											<span>{client.address}</span>
										</span>
									);
								})}
								<div className="top-navbar__inner__dropdown__tab"></div>
							</div>
						)}
					</>
				)}

				<div className="top-navbar__inner__toggle" onClick={toggleDropdown} ref={toggleRef}>
					<span className="top-navbar__inner__toggle__letter" ref={letterRef}>
						{currentUserInitials}
					</span>
				</div>

				{openDropdown && (
					<div className="top-navbar__inner__dropdown" ref={dropdownRef}>
						<div className="top-navbar__inner__dropdown__tab  " onClick={logout}>
							<span className="top-navbar__inner__dropdown__tab__txt top-navbar__inner__dropdown__tab__txt--logout">
								Logout
								<LogOut size={20} className="top-navbar__inner__dropdown__tab__icon" />
							</span>
						</div>
					</div>
				)}
			</div>
		</div>
	);
};

export default TopNavbar;
