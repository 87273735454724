/* eslint-disable react/prop-types */
// UploadedItemsList.js
import "./UploadedItemsList.scss";

import React from "react";

const UploadedItemsList = ({ files }) => {
	let hasFiles = files.length > 0;
	return (
		<div className="uploaded-items-list">
			{hasFiles && (
				<ul>
					{files.map((file, index) => (
						<li key={index}>
							{file.name} - {file.size} bytes
						</li>
					))}
				</ul>
			)}
			{!hasFiles && <p>No files uploaded yet.</p>}
		</div>
	);
};

export default UploadedItemsList;
