import React, { useEffect, useState } from "react";
import { Route, useNavigate, Routes } from "react-router-dom";
import Documents from "../pages/documents/Documents";
import Sidebar from "../reusable/side_bar/Sidebar";
import "../reusable/side_bar/Sidebar.scss";
import "./PrivateRoutes.scss";
import TopNavbar from "../reusable/top_navbar/TopNavbar";
import PropTypes from "prop-types";
import { useAuth } from "../contexts/AuthContext";
import UserService from "../services/UserService";

function PrivateRoutes() {
	const { loggedIn } = useAuth();
	const navigate = useNavigate();

	const [currentClient, setCurrentClient] = useState({});
	const [currentUserInitials, setCurrentUserInitials] = useState("JD");
	const [userClients, setUserClients] = useState([]);

	const getUserClients = async () => {
		try {
			const response = await UserService.fetchClients();
			if (response && response.length > 0) {
				setUserClients(response);

				const storedCurrentClient = JSON.parse(localStorage.getItem("currentClient"));

				const initialCurrentClient = storedCurrentClient || response[0];
				setCurrentClient(initialCurrentClient);
				setCurrentUserInitials(UserService.getCurrentUserInitials());
			}
		} catch (error) {
			console.log(error);
		}
	};

	// Effect to redirect if not logged in
	useEffect(() => {
		if (!loggedIn) {
			navigate("/");
		} else {
			getUserClients();
		}
	}, [loggedIn, navigate]);

	// Effect to update localStorage when currentClient changes
	useEffect(() => {
		if (currentClient && Object.keys(currentClient).length !== 0) {
			localStorage.setItem("currentClient", JSON.stringify(currentClient));
		}
	}, [currentClient]);

	return (
		<div className="PrivateRoutes">
			<TopNavbar userClients={userClients} currentClient={currentClient} setCurrentClient={setCurrentClient} currentUserInitials={currentUserInitials} />
			<Sidebar />

			<Routes>
				<Route path="/" element={<Documents currentClient={currentClient} />} />
			</Routes>
		</div>
	);
}

// Define propTypes for the component
PrivateRoutes.propTypes = {
	logout: PropTypes.func,
};

export default PrivateRoutes;
