/* eslint-disable react/prop-types */
// Dropzone.js
import "./Dropzone.scss";
import React, { useCallback, useRef } from "react";
import UploadedItemsList from "./UploadedItemsList";
import CustomBtn from "../../../reusable/custom_btn/CustomBtn";
import DocumentService from "../../../services/DocumentService";
import { toast } from "react-hot-toast";

const Dropzone = ({ onFilesAdded, files, onClose }) => {
	const [isUploading, setIsUploading] = React.useState(false);

	const fileInputRef = useRef();

	const handleDragOver = (e) => {
		e.preventDefault();
	};

	const handleDrop = useCallback(
		(e) => {
			e.preventDefault();
			const files = e.dataTransfer.files;
			if (files && files.length) {
				onFilesAdded(files);
			}
		},
		[onFilesAdded],
	);

	const onFilesAddedClick = () => {
		fileInputRef.current.click();
	};

	const handleFilesAdded = (e) => {
		const files = e.target.files;
		if (files && files.length) {
			onFilesAdded(files);
		}
	};

	const onUpload = async () => {
		try {
			setIsUploading(true);
			let response = await DocumentService.upload({
				files,
			});

			// Validate the response to ensure it's not empty or undefined
			if (!response) {
				throw new Error("Failed to upload");
			}

			// Success: Dismiss loading and show success message
			toast.dismiss();
			toast.success("Successfully uploaded!");
			onClose();
		} catch (error) {
			console.log(error);

			toast.dismiss(); // Ensure loading is dismissed in case of error
			toast.error("Sorry, something went wrong.");
		}

		setIsUploading(false);
	};

	return (
		<div className="dropzone">
			<div className="dropzone__inner" onDragOver={handleDragOver} onDrop={handleDrop} onClick={onFilesAddedClick}>
				<input
					ref={fileInputRef}
					className="fileInput"
					type="file"
					multiple
					onChange={handleFilesAdded}
					style={{ display: "none" }} // Hide the file input element
				/>
				<div className="dropzone__info">
					<img className="dropzone__info__upload-icon" alt="upload cloud icon" src={"./assets/upload_cloud_icon.svg"} />
					<span className="dropzone__info__txt">Drag and drop files here or click to select files</span>
				</div>
				<UploadedItemsList files={files} />{" "}
				<CustomBtn
					className="dropzone__footer__upload-btn"
					view={files.length === 0 ? "secondary" : "primary"}
					label={isUploading ? "Uploading..." : "Upload"}
					onClick={(event) => {
						// prevent event bubbling
						event.stopPropagation();
						onUpload();
					}}
					disabled={isUploading}
				/>
			</div>
			<CustomBtn className="dropzone__footer__cancel-btn" view="secondary" label="Cancel" onClick={onClose} />
		</div>
	);
};

export default Dropzone;
