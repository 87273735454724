import UserService from "./UserService";
import Kichiri from "./KichiriService";

const PlaidService = {
	/**
	 * Retrieves a list of documents for a given client ID.
	 *
	 * @param {String} plaidLinkPublicId - The ID of the client whose documents are to be retrieved.
	 * @returns {Promise<Object>} The API response containing the list of documents.
	 */
	async getPlaidLink({ plaidLinkPublicId }) {
		try {
			if (!plaidLinkPublicId) {
				return;
			}

			let { data } = await Kichiri.tools.createPlaidLinkToken({}, { publicId: plaidLinkPublicId });

			return data;
		} catch (error) {
			console.log(error);
		}
	},

	/**
	 * Completes the setup process for a client connecting their bank accounts to Workflow through Plaid
	 *
	 * @param {Number} clientId
	 * @param {String} publicId
	 * @param {String} publicToken
	 * @param {Object} payload
	 * @returns {Promise<Object>}
	 */
	async completeSetup({ clientId, publicId, publicToken, payload }) {
		try {
			if (!publicId) {
				return;
			}

			let { data } = await Kichiri.tools.completePlaidSetup(
				{
					clientId,
					publicId,
					publicToken,
					payload,
				},
				{},
			);

			return data;
		} catch (error) {
			console.log(error);
		}
	},
};

export default PlaidService;
