/* eslint-disable react/prop-types */
import React, { useEffect, useState } from "react";
import "./DocumentsHeader.scss";

// eslint-disable-next-line react/prop-types
const DocumentsHeader = ({ activeFile, hideTitle, activeIndex, onClose, setFileAction, loading, setShowDropzone }) => {
	const [expand, setExpand] = useState(false);

	useEffect(() => {
		// Set expand to true initially
		setExpand(true);

		return () => {
			setFileAction(null);
			setExpand(false);
		};
	}, [setFileAction, setExpand]);

	let isRowSelected = activeIndex !== null;

	return (
		<div className="documents-header">
			{!hideTitle && <span className="documents-header__title">Documents</span>}
			{isRowSelected && (
				<div className={`documents-header__edit-btn-container ${expand ? "expand" : ""}`}>
					{loading && <span className="documents-header__edit-btn-container__txt">Loading..</span>}
					{!loading && (
						<div className="documents-header__edit-btn-container__inner">
							<div className="documents-header__edit-btn-container__inner__x-container">
								<img
									onClick={onClose}
									className="documents-header__edit-btn-container__inner__x-container__icon--x"
									alt="x icon"
									src={"./assets/x-icon.svg"}
								/>

								<span className="documents-header__edit-btn-container__inner__x-container__file-name">
									{activeFile.name + activeFile?.extension}
								</span>
							</div>

							<div className="documents-header__edit-btn-container__inner__actions">
								<img
									onClick={() => {
										setFileAction("download");
									}}
									className="documents-header__edit-btn-container__inner__icon--download"
									alt="download icon"
									src={"./assets/download-icon.svg"}
								/>
								<img
									onClick={() => {
										setFileAction("delete");
									}}
									className="documents-header__edit-btn-container__inner__icon--trash"
									alt="trash icon"
									src={"./assets/trash-icon.svg"}
								/>
								<img
									onClick={() => {
										setFileAction("view");
									}}
									className="documents-header__edit-btn-container__inner__icon--eye"
									alt="eye icon"
									src={"./assets/eye-icon.svg"}
								/>{" "}
							</div>
						</div>
					)}
				</div>
			)}
			{!isRowSelected && (
				<div
					className="documents-header__new-btn"
					onClick={() => {
						setShowDropzone(true);
					}}
				>
					<img className="documents-header__new-btn__icon" alt="plus icon" src={"./assets/plus-icon.svg"} />
					<span className="documents-header__new-btn__txt">New</span>
				</div>
			)}
		</div>
	);
};

export default DocumentsHeader;
