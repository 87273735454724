import Kichiri from "./KichiriService";

/**
 * AuthService provides authentication-related functions, interfacing with the Kichiri client portal.
 */
const AuthService = {
	/**
	 * Attempts to log in a user with their email and password.
	 *
	 * @param {string} email - The user's email.
	 * @param {string} password - The user's password.
	 * @returns {Promise<Object>} The response from the login attempt.
	 */
	async login({ email, password }) {
		try {
			let response = await Kichiri.client_portal.login({
				email,
				password,
			});
			return response;
		} catch (error) {
			console.log(error);
		}
	},

	/**
	 * Creates a new account with the provided credentials and setup token.
	 *
	 * @param {string} email - The email for the new account.
	 * @param {string} password - The password for the new account.
	 * @param {string} confirmedPassword - The confirmed password for the new account.
	 * @param {string} setupToken - The setup token required for account creation.
	 * @returns {Promise<Object>} The response from the account setup attempt.
	 */
	async createAccount({ email, password, confirmedPassword, setupToken }) {
		try {
			let response = await Kichiri.client_portal.setupAccount({
				email,
				password,
				confirmedPassword,
				setupToken,
			});
			return response;
		} catch (error) {
			console.log(error);
		}
	},

	/**
	 * Verifies a given token.
	 *
	 * @param {string} token - The token to verify.
	 * @returns {Promise<Object>} The response from the token verification attempt.
	 */
	async verifyToken({ token }) {
		try {
			let response = await Kichiri.client_portal.verifyToken({
				token,
			});
			return response;
		} catch (error) {
			console.log(error);
		}
	},

	/**
	 * Initiates a password reset process for the user associated with the given email.
	 *
	 * @param {string} contactEmail - The email of the user requesting a password reset.
	 * @returns {Promise<Object>} The response from the password reset email attempt.
	 */
	async emailResetPassword({ contactEmail }) {
		try {
			let response = await Kichiri.client_portal.emailResetPassword({
				contactEmail,
			});
			return response;
		} catch (error) {
			console.log(error);
		}
	},
};

export default AuthService;
