import APIDoc from "../api/api";
import Kichiri from "react-kichiri";

const env = {
	development: {
		rest: "http://localhost:5001/api",
	},
	production: {
		rest: "https://api.getdocify.com/api",
	},
};

export const ConfigurationService = {
	/**
	 * Returns the environment endpoints set above
	 *
	 * @return {String}
	 */
	resolveApi: () => {
		// eslint-disable-next-line no-undef
		return env[process.env.NODE_ENV || "production"];
	},
};

export default new Kichiri(APIDoc, ConfigurationService.resolveApi().rest, false).api;
