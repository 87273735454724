// useForm.js
import { useState } from "react";

const useForm = (onSubmit, initialInputs, validate) => {
	const [inputs, setInputs] = useState(initialInputs);
	const [errors, setErrors] = useState({});

	const handleInputChange = (event) => {
		const { name, value } = event.target;

		// Clear the error for the current input when it changes
		setErrors((prevErrors) => ({
			...prevErrors,
			[name]: undefined,
		}));

		setInputs((prevInputs) => ({
			...prevInputs,
			[name]: value,
		}));
	};

	const handleSubmit = (event) => {
		if (event) {
			event.preventDefault();
		}

		if (validate) {
			const newErrors = validate(inputs);
			setErrors(newErrors);

			if (Object.keys(newErrors).length > 0) {
				return;
			}
		}

		onSubmit();
	};

	return {
		inputs,
		errors,
		setErrors,
		handleInputChange,
		handleSubmit,
		setInputs, // You can use this to set the inputs from outside the hook if needed
	};
};

export default useForm;
