import React, { createContext, useContext, useEffect, useState } from "react";
import PropTypes from "prop-types";
import UserService from "../services/UserService";

import { USER_TOKEN_KEY, USER_TOKEN_KEY_EXPIRY } from "../constants/AuthConstants";

const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
	const [loggedIn, setLoggedIn] = useState(false);
	const [authToken, setAuthToken] = useState(null);
	const [expirationTime, setExpirationTime] = useState(null);

	// Function to check token expiration
	const checkTokenExpiration = () => {
		if (authToken && expirationTime) {
			const currentTime = new Date();
			if (currentTime >= expirationTime) {
				// Token has expired, perform logout
				logout();
			}
		}
	};

	// Function to handle login action
	const login = async ({ authData, dayOfMonth, updatedAtDate, userData }) => {
		// Set the expiration date with the same year and month, but a different day
		const expirationDate = new Date(updatedAtDate.getFullYear(), updatedAtDate.getMonth(), dayOfMonth + 30);

		setLoggedIn(true);
		setAuthToken(authData.token);
		setExpirationTime(expirationDate);

		await UserService.storeUserData({
			userData,
			token: authData.token,
			expirationDate,
		});
	};

	// Function to perform logout
	const logout = async () => {
		setLoggedIn(false);
		setAuthToken(null);
		setExpirationTime(null);

		await UserService.clearUserData();
	};

	// Effect to check token expiration on mount and every minute
	useEffect(() => {
		// Try to retrieve token and expiration from localStorage
		const storedAuthToken = localStorage.getItem(USER_TOKEN_KEY);
		const storedExpirationTime = localStorage.getItem(USER_TOKEN_KEY_EXPIRY);

		if (storedAuthToken && storedExpirationTime) {
			setAuthToken(storedAuthToken);
			setExpirationTime(new Date(storedExpirationTime));
			setLoggedIn(true);
		}

		checkTokenExpiration();

		const interval = setInterval(() => {
			checkTokenExpiration();
		}, 60000);

		return () => clearInterval(interval);
	}, []);

	return <AuthContext.Provider value={{ loggedIn, login, logout }}>{children}</AuthContext.Provider>;
};

AuthProvider.propTypes = {
	children: PropTypes.node.isRequired,
};

export const useAuth = () => {
	return useContext(AuthContext);
};
